import React, { useState, useEffect } from 'react';
import { styled, theme } from '../Theme';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { media } from '@glitz/core';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import ResetPasswordPageModel from './Models/ResetPasswordPageModel.interface';
import {
  GenerateNewPasswordLink,
  ResetPassword,
} from '../../Features/AccountPage/Account';
import LeftPanel from '../LeftNavigationLayout/LeftPanel';
import { getUrlParameter } from '../Shared/Common/Helpers';
import SendEmailStep from './SendEmailStep';
import UpdatePasswordStep from './UpdatePasswordStep';
import ResetPasswordResponseMessageModel from './Models/ResetPasswordResponseMessageModel.interface';

function ResetPasswordPage() {
  const {
    pageHeading,
    pageText,
    resetPasswordHeading,
    resetPasswordText,
    userValidated,
    error,
  } = useCurrentPage<ResetPasswordPageModel>();
  const [email, setEmail] = useState<string>(getUrlParameter('email') || '');
  const [password, setPassword] = useState<string>('');
  const [repeatedPassword, setRepeatedPassword] = useState<string>('');
  const [resultMessage, setResultMessage] = useState<
    ResetPasswordResponseMessageModel[]
  >([]);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('');
  const [token, setToken] = useState<string>('');

  const {
    staticPages: { resetPasswordPage },
    translations: {
      'resetPasswordPage/passwordDifferent': passwordDifferentLabel,
      'resetPasswordPage/errorHeader': errorHeaderLabel,
    },
  } = useAppSettingsData();

  const onResult = (message: ResetPasswordResponseMessageModel) => {
    setShowSuccess(true);
    setShowError(false);
    setResultMessage([{ header: message.header, text: message.text }]);
  };

  const onError = (errors: ResetPasswordResponseMessageModel[]) => {
    setShowError(true);
    setShowSuccess(false);
    setResultMessage(errors);
  };

  const generatePasswordEmail = async () => {
    await GenerateNewPasswordLink(resetPasswordPage, email, onResult, onError);
  };
  useEffect(() => {
    setUserId(getUrlParameter('userId'));
    setToken(getUrlParameter('token'));
  }, []);

  const resetPassword = async () => {
    if (password.localeCompare(repeatedPassword) === 0) {
      await ResetPassword(
        resetPasswordPage,
        userId,
        token,
        password,
        resetPasswordSuccess,
        resetPasswordError
      );
    } else {
      setShowError(true);
      setResultMessage([
        { header: errorHeaderLabel, text: passwordDifferentLabel },
      ]);
    }
  };

  const resetPasswordSuccess = (message: ResetPasswordResponseMessageModel) => {
    setShowError(false);
    setShowSuccess(true);
    setResultMessage([{ header: message.header, text: message.text }]);
    setRepeatedPassword('');
    setPassword('');
  };

  const resetPasswordError = (errors: ResetPasswordResponseMessageModel[]) => {
    setShowError(true);
    setResultMessage(errors);
  };

  const leftComponent = (
    <>
      <LeftHeader>{pageHeading}</LeftHeader>
      <IntroText>{pageText}</IntroText>
    </>
  );
  return (
    <LeftPanel leftComponent={leftComponent}>
      <FormContainer>
        {userValidated === undefined && (
          <SendEmailStep
            resetPasswordHeading={resetPasswordHeading}
            email={email}
            setEmail={setEmail}
            proceedAction={generatePasswordEmail}
            resultMessage={resultMessage}
            showError={showError}
            showSuccess={showSuccess}
          />
        )}
        {userValidated !== undefined && (
          <UpdatePasswordStep
            resetPasswordText={resetPasswordText}
            password={password}
            repeatedPassword={repeatedPassword}
            setPassword={setPassword}
            setRepeatedPassword={setRepeatedPassword}
            proceedAction={resetPassword}
            resultMessage={resultMessage}
            setResultMessage={setResultMessage}
            showError={showError}
            setShowError={setShowError}
            showSuccess={showSuccess}
            userValidated={userValidated}
            error={error}
          />
        )}
      </FormContainer>
    </LeftPanel>
  );
}

const FormContainer = styled.div({
  font: { size: theme.psi, weight: theme.fontWeight.lighter },
  color: theme.black,
  lineHeight: theme.lineHeight.normal,
  letterSpacing: theme.letterSpacing.medium,
  maxWidth: theme.spacing(100),
  width: '100%',
  padding: { y: theme.spacing(8) },
  margin: { x: 'auto' },

  ...media(theme.mediaQuery.mediaMaxMedium, {
    padding: { x: theme.spacing(4) },
  }),
});

const LeftHeader = styled.h1({
  font: { size: theme.mu, weight: theme.fontWeight.bold },
  color: theme.black,
  margin: { bottom: theme.spacing(4) },
  lineHeight: theme.lineHeight.tight,
  letterSpacing: theme.letterSpacing.moreWide,
});

const IntroText = styled.p({
  font: { size: theme.gamma },
  lineHeight: theme.lineHeight.normal,
  letterSpacing: theme.letterSpacing.nearMedium,
  color: theme.black,
});

export default ResetPasswordPage;
