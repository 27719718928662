import React, { useEffect, useState } from 'react';
import { styled, theme } from '../Theme';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { FeedbackButton } from '../Shared/Button/Button';
import { KexInputValidation } from '../Shared/Input/KexInputValidation';
import KexInput from '../Shared/Input/KexInput';
import TepeNotification from '../Shared/Common/TepeNotification';
import ResetPasswordResponseMessageModel from './Models/ResetPasswordResponseMessageModel.interface';

type PropTypes = {
  resetPasswordText: string;
  password: string;
  setPassword: (value: string) => void;
  repeatedPassword: string;
  setRepeatedPassword: (value: string) => void;
  proceedAction: () => Promise<void>;
  showError: boolean;
  setShowError: (value: boolean) => void;
  showSuccess: boolean;
  resultMessage: ResetPasswordResponseMessageModel[];
  setResultMessage: (value: ResetPasswordResponseMessageModel[]) => void;
  userValidated: number;
  error: ResetPasswordResponseMessageModel;
};
const UpdatePasswordStep = ({
  resetPasswordText,
  password,
  setPassword,
  repeatedPassword,
  setRepeatedPassword,
  showError,
  showSuccess,
  proceedAction,
  resultMessage,
  setResultMessage,
  userValidated,
  setShowError,
  error,
}: PropTypes) => {
  const {
    translations: {
      'validation/invalidpassword': invalidpasswordLabel,
      'resetPasswordPage/enterNewPassword': enterNewPasswordLabel,
      'account/repeatPassword': repeatPasswordLabel,
      'account/password': passwordLabel,
      'resetPasswordPage/proceed': proceedLabel,
      'validation/invalidrepeatpassword': invalidRepeatPassword,
      'registrationpage/password/atLeastCharacters': atLeastCharacters,
      'registrationpage/password/atLeastOneNumber': atLeastOneNumber,
      'registrationpage/password/atLeastOneUppercase': atLeastOneUppercase,
      'registrationpage/password/passwordInformation': passwordInformationText,
      'registrationpage/password/atLeastOneSpecialCharacter': atLeastOneSpecialCharacter,
    },
  } = useAppSettingsData();
  const [passwordMatch, setPasswordMatch] = useState<boolean>(false);

  useEffect(() => {
    setPasswordMatch(password === repeatedPassword);
  }, [password, repeatedPassword, setPasswordMatch]);

  const [passwordValid, setPasswordValid] = useState<boolean>(false);

  useEffect(() => {
    if (error && userValidated === 0) {
      setResultMessage([{ header: error.header, text: error.text }]);
      setShowError(true);
    }
  }, [userValidated, error, setResultMessage, setShowError]);
  return (
    <>
      <RightHeader>{enterNewPasswordLabel}</RightHeader>
      <FormDetailText>{resetPasswordText}</FormDetailText>
      <KexInputValidation
        onInputsValid={() => setPasswordValid(true)}
        onInputsInvalid={() => setPasswordValid(false)}
      >
        <KexInput
          title={passwordLabel}
          validation={{
            required: true,
            minLength: 8,
            maxLength: 30,
            pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-,.]).{8,}$/,
            errorMessage: invalidpasswordLabel,
          }}
          value={password}
          name="password1"
          type="password"
          useDebounce
          onChange={setPassword}
        />
        <KexInput
          title={repeatPasswordLabel}
          validation={{
            required: true,
            minLength: 8,
            maxLength: 30,
            pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-,.]).{8,}$/,
            valueToMatch: password,
            errorMessage: passwordMatch
              ? invalidRepeatPassword
              : invalidpasswordLabel,
          }}
          value={repeatedPassword}
          name="password2"
          type="password"
          onChange={setRepeatedPassword}
          useDebounce
          onKeyDown={e => e.key === 'Enter' && proceedAction()}
        />
      </KexInputValidation>
      <TextWrapper>
        <Text>{atLeastCharacters}</Text>
        <Text>{atLeastOneNumber}</Text>
        <Text>{atLeastOneUppercase}</Text>
        <Text>{atLeastOneSpecialCharacter}</Text>
        <PasswordText>{passwordInformationText}</PasswordText>
      </TextWrapper>
      {(showError || showSuccess) &&
        resultMessage &&
        resultMessage.map(
          (msg: ResetPasswordResponseMessageModel, index: number) => (
            <TepeNotification
              key={index + msg.text}
              isError={showError}
              message={msg}
            />
          )
        )}

      <ButtonContainer>
        <StyledFeedbackButton
          disabled={
            password === '' ||
            repeatedPassword === '' ||
            !passwordMatch ||
            userValidated === 0 ||
            !passwordValid
          }
          action={proceedAction}
        >
          <ButtonText>{proceedLabel}</ButtonText>
        </StyledFeedbackButton>
      </ButtonContainer>
    </>
  );
};

export default UpdatePasswordStep;

const ButtonText = styled.div({ fontSize: theme.gamma });

const StyledFeedbackButton = styled(FeedbackButton, {});

const ButtonContainer = styled.div({
  margin: { x: 'auto', y: theme.spacing(4) },
  display: 'flex',
  justifyContent: 'end',
});

const Text = styled.p({
  font: { size: theme.alpha, weight: theme.fontWeight.bold },
});

const TextWrapper = styled.div({
  margin: { y: theme.spacing(3) },
});

const PasswordText = styled.p({
  font: { size: theme.alpha },
  marginTop: theme.spacing(2),
});

const FormDetailText = styled.p({
  font: { size: theme.spacing(4), weight: theme.fontWeight.normal },
  lineHeight: theme.lineHeight.relaxed,
  marginBottom: theme.spacing(2),
});

const RightHeader = styled.h4({
  font: { size: theme.zeta, weight: theme.fontWeight.bold },
  paddingBottom: theme.spacing(4),
});
