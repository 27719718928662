import React from 'react';
import { styled, theme } from '../Theme';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { KexInputValidation } from '../Shared/Input/KexInputValidation';
import KexInput from '../Shared/Input/KexInput';
import TepeNotification from '../Shared/Common/TepeNotification';
import { FeedbackButton } from '../Shared/Button/Button';
import ResetPasswordResponseMessageModel from './Models/ResetPasswordResponseMessageModel.interface';

type PropTypes = {
  resetPasswordHeading: string;
  email: string;
  setEmail: (value: string) => void;
  proceedAction: () => Promise<void>;
  showError: boolean;
  showSuccess: boolean;
  resultMessage: ResetPasswordResponseMessageModel[];
};

const SendEmailStep = ({
  resetPasswordHeading,
  email,
  setEmail,
  proceedAction,
  resultMessage,
  showError,
  showSuccess,
}: PropTypes) => {
  const {
    translations: {
      'resetPasswordPage/required': requiredLabel,
      'resetPasswordPage/getNewPassword': getNewPasswordLabel,
      'account/email': emailLabel,
    },
  } = useAppSettingsData();
  return (
    <>
      <RightHeader>{resetPasswordHeading}</RightHeader>
      <KexInputValidation>
        <KexInput
          title={emailLabel}
          validation={{
            required: true,
            errorMessage: requiredLabel,
          }}
          value={email}
          onChange={setEmail}
          onKeyDown={e => e.key === 'Enter' && proceedAction()}
          name="email"
          css={{ backgroundColor: theme.white }}
        />
      </KexInputValidation>
      {(showError || showSuccess) &&
        resultMessage &&
        resultMessage.map(
          (message: ResetPasswordResponseMessageModel, index: number) => (
            <TepeNotification
              key={index + message.header}
              isError={showError}
              message={message}
            />
          )
        )}
      <ButtonContainer>
        <StyledFeedbackButton disabled={email === ''} action={proceedAction}>
          <ButtonText>{getNewPasswordLabel}</ButtonText>
        </StyledFeedbackButton>
      </ButtonContainer>
    </>
  );
};

const RightHeader = styled.h4({
  font: { size: theme.zeta, weight: theme.fontWeight.bold },
  paddingBottom: theme.spacing(4),
});

const ButtonText = styled.div({ fontSize: theme.gamma });

const StyledFeedbackButton = styled(FeedbackButton, {});

const ButtonContainer = styled.div({
  margin: { x: 'auto', y: theme.spacing(4) },
  display: 'flex',
  justifyContent: 'end',
});

export default SendEmailStep;
