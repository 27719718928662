import React from 'react';
import { styled, theme } from '../../Theme';
import ExclamationMarkIcon from '../Icons/ExclamationMarkIcon';

type PropTypes = {
  message?: { header?: string; text?: string };
  isError: boolean;
  text?: string;
  greyBackground?: boolean;
  children?: React.ReactNode;
};

function TepeNotification({
  message,
  text,
  isError = false,
  greyBackground = false,
  children,
}: PropTypes) {
  const notificationText = (
    <NotificationTextWrapper>
      {message?.header && (
        <MessageHeader
          css={message.text ? { marginBottom: theme.spacing(2) } : {}}
        >
          {message.header}
        </MessageHeader>
      )}
      {message?.text && <TextWrapper> {message.text}</TextWrapper>}
      {text && <TextWrapper> {text} </TextWrapper>}
      {children && children}
    </NotificationTextWrapper>
  );
  return (
    <>
      {isError ? (
        <Error>
          <StyledErrorIcon />
          {notificationText}
        </Error>
      ) : !greyBackground ? (
        <Message>
          <StyledMessageIcon />
          {notificationText}
        </Message>
      ) : (
        <OutOfStockMessage>
          <StyledMessageIcon css={{ fill: theme.black }} />
          {notificationText}
        </OutOfStockMessage>
      )}
    </>
  );
}

export default TepeNotification;

const Base = styled.div({
  fontSize: theme.alpha,
  padding: { xy: theme.spacing(4) },
  margin: { y: theme.spacing(2) },
  display: 'flex',
});

const TextWrapper = styled.div({
  fontSize: theme.alpha,
  display: 'flex',
});

const Message = styled(Base, {
  color: theme.successText,
  backgroundColor: theme.spotGreen,
});

const OutOfStockMessage = styled(Base, {
  color: theme.black,
  backgroundColor: theme.thinGray,
});

const NotificationTextWrapper = styled.div({
  marginLeft: theme.spacing(2),
});

const MessageHeader = styled.div({
  fontWeight: theme.fontWeight.bold,
});

const Error = styled(Base, {
  color: theme.errorText,
  backgroundColor: theme.errorPrimary,
  display: 'flex',
});

const StyledMessageIcon = styled(ExclamationMarkIcon, {
  width: theme.spacing(4),
  height: theme.spacing(4),
  flexShrink: 0,
  fill: theme.successText,
});

const StyledErrorIcon = styled(ExclamationMarkIcon, {
  width: theme.spacing(4),
  height: theme.spacing(4),
  flexShrink: 0,
  fill: theme.errorText,
});
