import { media, pseudo } from '@glitz/core';
import { styled } from '@glitz/react';
import React from 'react';
import { theme } from '../Theme';

type PropType = {
  leftComponent?: React.ReactNode;
  children: React.ReactNode;
};

function LeftPanelLayout({ leftComponent, children }: PropType) {
  return (
    <LeftComponent>
      <Left>{leftComponent}</Left>
      <Right>{children}</Right>
    </LeftComponent>
  );
}

const LeftComponent = styled.div({
  display: 'grid',
  gridGap: theme.gamma,
  gridTemplateColumns: '1fr',
  width: '100%',
  height: '100%',
  minHeight: '95vh',
  justifyContent: 'center',
  gridRowGap: 0,
  margin: {
    x: 'auto',
  },
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridTemplateColumns: '3fr 5fr',
    gridGap: 0,
  }),
  maxWidth: theme.contentMaxWidth,
});

const GridBox = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: { x: theme.spacing(16), y: theme.spacing(8) },
});

const Left = styled(GridBox, {
  position: 'relative',
  backgroundColor: theme.thinAqua,
  ...media(theme.mediaQuery.mediaMaxLarge, {
    padding: { x: theme.spacing(4), y: theme.spacing(6) },
  }),
  ...pseudo(':before', {
    content: "' '",
    backgroundColor: theme.thinAqua,
    position: 'absolute',
    display: 'block',
    width: '100%',
    height: '100%',
    left: '-100%',
    top: '0',
  }),
});

const Right = styled.div({});

export default LeftPanelLayout;
